import styled from "styled-components"
import { XL, BREAKPOINT_CEL_ONLY } from "../../../utils/constants"

export const Wrapper = styled.div`
  background: url(${props => props.bg}) no-repeat center center / cover;

  @media (max-width: ${BREAKPOINT_CEL_ONLY}) {
    background: none;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 380px;
      left: 0;
      background: url(${props => props.bg}) no-repeat center center / cover;
 
    }
  }
`
export const ModulesContainer = styled.div`
  position: relative;
  top: 120px;
  margin-bottom: 100px;

  @media (max-width: ${BREAKPOINT_CEL_ONLY}) {
    top: 77px;
    margin-bottom: 80px;
  }

  @media (max-width: ${XL}) {
    padding: 0 30px;
  }
`

import styled from "styled-components"
import { LG, BREAKPOINT_CEL_ONLY } from "../../../utils/constants"
import { MainTextLink, TextLinkWrap } from "../../../utils/GlobalComponents"

export const Wrapper = styled.div`
  background-color: ${props => props.theme.neutralSilver};
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 25px;
  padding-top: 90px;
  padding-bottom: 180px;
  max-width: 940px;
  margin: 0 auto;

  @media (max-width: ${LG}) {
    grid-template-columns: 1fr 1fr;
    padding: 90px 30px;
  }
  @media (max-width: ${BREAKPOINT_CEL_ONLY}) {
    grid-template-columns: 1fr;
    padding: 45px 30px 90px;
  }
`

export const Card = styled.div`
  box-shadow: 1px 28px 60px rgba(0, 38, 52, 0.08);
  border-radius: 8px;
  text-align: center;
  padding: 30px 30px;
  background-color: white;
  cursor: pointer;

  transition: ${props => props.theme.defaultTransition()};

  &:hover {
    box-shadow: 2px 6px 48px rgba(243, 139, 151, 0.27);
  }

  h3 {
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    color: ${props => props.theme.navy};
    margin-bottom: 10px;
  }

  h4 {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${props => props.theme.navy};
    /*     padding-bottom: 70px; */

    max-width: 184px;
    margin: 0 auto;
  }

  .center__image-wrapper {
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 45px;
  }

  img {
    max-width: 121px;
  }

  @media (max-width: ${BREAKPOINT_CEL_ONLY}) {
    text-align: left;
    h4 {§
      max-width: unset;
      text-transform: capitalize;
      line-height: 21px;
      font-weight: 500;
      height: 90px;
      margin: 0;
    }
    h3 {
      font-size: 22px;
      line-height: 31px;
      margin-bottom: 2px;
    }
    .center__image-wrapper {
      margin-bottom: 25px;
    }
    img {
      width: 64px;
      height: auto;
    }
  }
`
export const TextContainer = styled.div`
  height: 150px;
  @media (max-width: ${BREAKPOINT_CEL_ONLY}) {
    height: auto;
  }
`

export const ViewDetails = styled(MainTextLink)`
  /*  border: 0;
  cursor: pointer; */

  
`
export const LinkWrap = styled(TextLinkWrap)`
  margin: 0 auto;


  @media(max-width:${LG}){
    margin-left:0;
  }
`

import React from "react"
import { useStaticQuery } from "gatsby"
import { DefaultWrapper } from "../../../utils/GlobalComponents"

import { Wrapper, Headline, Title, TopTitle } from "./style"
import Centers from "../Centers/Centers"

const Foundation = () => (
  <Wrapper>
    <DefaultWrapper>
      <Headline>
        <TopTitle data-aos="fade-up" data-aos-duration="1000">Cohen Lyme & Tickborne Disease Initiative</TopTitle>
        <Title data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500" maxWidth="unset">
          LymeMIND Consortium Centers
            </Title>
      </Headline>
    </DefaultWrapper>
    <Centers />
  </Wrapper>
)

export default Foundation

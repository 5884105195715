import styled from "styled-components"
import { LG } from "../../../utils/constants"

import {
  MainH2,
  BigParagraph,
  MainParagraph,
} from "../../../utils/GlobalComponents"

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: end;
  padding: 100px 30px;
  margin-left: 25px;

  @media (max-width: ${LG}) {
    grid-template-columns: 1fr;
    padding: 140px 30px 45px 0;
  }
`

export const Title = styled(MainH2)`
  margin-bottom: 30px;
`

export const Subtitle = styled(BigParagraph)`
  width: 100%;
  max-width: 437px;

  @media (max-width: ${LG}) {
    max-width: unset;
  }
`

export const Text = styled(MainParagraph)`
  width: 100%;
  max-width: 446px;
  color: ${props => props.theme.navy};
  @media (max-width: ${LG}) {
    max-width: unset;
  }
`

import React, { Fragment } from "react"
import { useStaticQuery } from "gatsby"
import moment from "moment"
import { DefaultWrapper } from "../../../utils/GlobalComponents"

import { Wrapper, Title, Text, ImageWrapper } from "./style"
import CalendarIcon from "../../../images/lyme-local-images/Calendar-48x48.png"
import PinIcon from "../../../images/lyme-local-images/Pin-48x48.png"

const Commons = ({ ...props }) => {
  const {
    allWordpressWpConsortiumEvents: { edges },
  } = useStaticQuery(graphql`
    query MeetingQuery {
      allWordpressWpConsortiumEvents {
        edges {
          node {
            date
            title
            acf {
              event_date
              event_description
              event_title
              event_venue
              show_event
            }
          }
        }
      }
    }
  `)

  const sortedDates = edges
    .slice()
    .sort(
      (a, b) =>
        parseInt(moment(b.node.date).format("x")) +
        parseInt(moment(a.node.date).format("x"))
    )

  const lastEdge = sortedDates[0]

  

  return (
    <Fragment>
      {lastEdge !== null && lastEdge.node.acf.show_event === "yes" && (
        <DefaultWrapper {...props}>
          <Wrapper>
            <div>
              <Title data-aos="fade-up" data-aos-duration="1000">
                {lastEdge.node.title}
              </Title>
              <Text
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="300"
                dangerouslySetInnerHTML={{
                  __html: lastEdge.node.acf.event_description,
                }}
              ></Text>
              <ul>
                <li
                  data-aos="fade"
                  data-aos-duration="600"
                  data-aos-delay="500"
                >
                  <img src={CalendarIcon} alt="Calendar" />
                  <span>{lastEdge.node.acf.event_date}</span>
                </li>
                <li
                  data-aos="fade"
                  data-aos-duration="600"
                  data-aos-delay="1000"
                >
                  <img src={PinIcon} alt="Pin" />
                  <span>{lastEdge.node.acf.event_venue}</span>
                </li>
              </ul>
            </div>

            <ImageWrapper
              bg={
                "https://cms.lymemind.org/wp-content/uploads/2020/05/c-module-2.jpg"
              }
            />
          </Wrapper>
        </DefaultWrapper>
      )}
    </Fragment>
  )
}

export default Commons

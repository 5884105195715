import React from "react"
import { DefaultWrapper } from "../../../utils/GlobalComponents"

import { Wrapper, Title, Subtitle, Text } from "./style"

const Hero = () => {
  return (
    <DefaultWrapper>
      <Wrapper>
        <div>
          <Title data-aos="fade" data-aos-duration="1000">
            Consortium
          </Title>
          <Subtitle
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="500"
          >
            The LymeMIND consortium is one of the main pillars of LymeMIND’s
            integrated solution.
          </Subtitle>
        </div>
        <div>
          <p>
            You can search the content aggregated from the consortium through
            LymeMIND Commons, find out about the 2021 consortium meeting, or see
            a complete list of all the centers that make up the LymeMIND
            consortium.
          </p>
        </div>
      </Wrapper>
    </DefaultWrapper>
  )
}

export default Hero

import React, { Fragment, useEffect, useState } from "react"

import Rodal from "rodal"
import "rodal/lib/rodal.css"
import { DefaultWrapper } from "../../../utils/GlobalComponents"
import {
  Wrapper,
  Grid,
  Title,
  Place,
  ModalImage,
  Text,
  ExternalLinksWrap,
  TextContainer,
} from "./style"

const Modal = ({ onClose, visible, info, animation }) => {
  const [textNumber, setTextNumber] = useState("")
  const [positionScroll, setPositionScroll] = useState(null)



  useEffect(() => {
    if (visible) {
      setPositionScroll(window.pageYOffset)
      setTimeout(() => {
        document.documentElement.style.overflowY = "scroll"
        document.body.style.top = `-${positionScroll}px`
        document.body.style.right = `0`
      }, 200)

      setTimeout(() => {
        document.body.style.position = "fixed"
      }, 400)

      let parser = new DOMParser()
      let htmlDoc = parser.parseFromString(
        info.center_external_links,
        "text/html"
      )
      setTextNumber(htmlDoc.getElementsByTagName("p").length)
    } else {
      setTimeout(() => {
        document.body.style.position = ""

        window.scrollTo(0, positionScroll)
      }, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, textNumber, positionScroll])

  return (
    <DefaultWrapper>
      <Wrapper>
        <Rodal
          enterAnimation={"slideUp"}
          leaveAnimation={"fade"}
          duration={300}
          animation={animation}
          visible={visible}
          onClose={onClose}
        >
          {info && (
            <Fragment>
              <Grid>
                <ModalImage>
                  <img
                    src={info.center_picture.source_url}
                    alt=""
                  />
                </ModalImage>
                <Title>{info.center_title}</Title>
                <Place>{info.center_place}</Place>
                <TextContainer>
                  <Text> {info.center_long_description}</Text>
                </TextContainer>
              </Grid>
              <ExternalLinksWrap
                textNumber={textNumber}
                dangerouslySetInnerHTML={{
                  __html: info.center_external_links,
                }}
              />
            </Fragment>
          )}
        </Rodal>
      </Wrapper>
    </DefaultWrapper>
  )
}

export default Modal

import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/Consortium/Hero/Hero"
import Modules from "../components/Consortium/Modules/Modules"
import Foundation from "../components/Consortium/Foundation/Foundation"

const Consortium = () => {
  return (
    <Layout>
      <SEO title="Consortium" />
      <Hero />
      <Modules />
      <Foundation />
    </Layout>
  )
}

export default Consortium

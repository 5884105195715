import React, { useState, useEffect } from "react"
import { useStaticQuery } from "gatsby"
import { DefaultWrapper } from "../../../utils/GlobalComponents"
import Modal from "../Modal/Modal"

import {
  Wrapper,
  Card,
  ViewDetails,
  Grid,
  TextContainer,
  LinkWrap,
} from "./style"

const Centers = () => {
  const [isDesktop, setIsDesktop] = useState(false)

  useEffect(() => {
    if (window.innerWidth >= 375) {
      setIsDesktop(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    allWordpressWpCenters: { edges },
  } = useStaticQuery(graphql`
    query CentersQuery {
      allWordpressWpCenters {
        edges {
          node {
            acf {
              center_external_links
              center_picture {
                source_url
                alt_text
              }
              center_long_description
              center_place
              center_title
            }
          }
        }
      }
    }
  `)

  const [modalOpen, setModalOpen] = useState({
    info: null,
    visible: false,
  })

  return (
    <Wrapper>
      <DefaultWrapper>
        <Modal
          animation={"fade"}
          visible={modalOpen.visible}
          onClose={() =>
            setModalOpen({
              info: null,
              visible: false,
            })
          }
          info={modalOpen.info}
        />
        <Grid>
          {edges.map(({ node }, i) => (
            <Card
              key={i}
              onClick={() => {
                setModalOpen({
                  visible: true,
                  info: node.acf,
                })
              }}
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-duration="1000"
              data-aos-delay={
                isDesktop
                  ? (i + 1) % 3 === 0
                    ? "400"
                    : (i + 2) % 3 === 0
                      ? "200"
                      : "0"
                  : "0"
              }
            >
              <div className="center__image-wrapper">
                <img
                  src={
                    node.acf.center_picture.source_url
                      ? node.acf.center_picture.source_url
                      : ""
                  }
                  alt={
                    node.acf.center_picture.alt_text &&
                    node.acf.center_picture.alt_text
                  }
                />
              </div>
              <TextContainer>
                <h3>{node.acf.center_title}</h3>
                <h4>{node.acf.center_place}</h4>
              </TextContainer>

              <LinkWrap>
                <ViewDetails to={""} as="div">
                  View Details
                </ViewDetails>
              </LinkWrap>
            </Card>
          ))}
        </Grid>
      </DefaultWrapper>
    </Wrapper>
  )
}

export default Centers

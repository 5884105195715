import styled from "styled-components"
import { MainH2, SupTitle } from "../../../utils/GlobalComponents"
import {LG} from "../../../utils/constants"

export const Wrapper = styled.div`
  margin-top: 350px;

  @media(max-width:${LG}){
    margin-top:200px;
  } 
  
`

export const Headline = styled.div`
  text-align: center;
  padding-bottom:110px;

  @media(max-width:${LG}){
  padding-bottom: 40px;
}
`

export const Title = styled(MainH2)`
margin-top:10px;
@media(max-width:${LG}){
  padding: 0 30px;
}
`

export const TopTitle = styled(SupTitle)`
  &:after {
    display: none;
  }
`

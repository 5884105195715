import React, { Fragment, useEffect, useState } from "react"

/* import Commons from "../Commons/Commons" */
import Meeting from "../Meeting/Meeting"

import { Wrapper, ModulesContainer } from "./style"
import BgImage from "../../../images/lyme-local-images/consortium-gradient.jpg"

const Modules = () => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (window.innerWidth <= 375) {
      setIsMobile(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <Wrapper bg={BgImage}>
        <ModulesContainer>
{/*           <Commons
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay={isMobile ? "0" : "1500"}
          /> */}
          <Meeting data-aos="fade-up" data-aos-duration="1000" />
        </ModulesContainer>
      </Wrapper>
    </Fragment>
  )
}

export default Modules

import styled from "styled-components"

import { LG, BREAKPOINT_CEL_ONLY } from "../../../utils/constants"
import ArrowIcon from "../../../images/smallarrowright.svg"

export const Wrapper = styled.div`
  .rodal-dialog {
    background-color: white;
    max-height: none;
    max-width: 936px;
    height: 97% !important;
    padding: 66px 66px 30px;
    box-shadow: 4px 20px 60px rgba(243, 139, 151, 0.5);
    margin: 10px auto 20px;
    scrollbar-color: red;
    overflow-y: scroll;

    @media (max-width: ${LG}) {
      height: 100% !important;
      margin: 0;
      padding: 60px 30px 40px 30px;
      overflow-y: scroll;
      margin: 0;
    }
  }

  .rodal-mask {
    background-color: rgba(250, 247, 245, 0.7);
  }

  .rodal-dialog::-webkit-scrollbar {
    width: 8px;
  }
  .rodal-dialog {
    scrollbar-width: thin;
    scrollbar-color: linear-gradient(0deg, #f38b97 20.01%, #f4904d 100%);
  }
  .rodal-dialog::-webkit-scrollbar-thumb {
    background: rgba(176, 201, 203, 0.22);
    border-radius: 20px;
  }

  .rodal-close {
    background-color: white;
    opacity: 1;
    border-radius: 50%;
    box-shadow: 0px 4px 9px rgba(0, 43, 52, 0.12);
    padding: 40px;
    transition: ${props => props.theme.defaultTransition()};
    top: 20px;
    right: 20px;

    &:after,
    &:before {
      width: 40%;

      background-color: ${props => props.theme.lightGreen};
      margin: 0 auto;
      opacity: 1;
      left: 30%;
    }

    &:hover {
      box-shadow: 0px 4px 9px rgba(0, 43, 52, 0.3);
    }

    @media (max-width: ${LG}) {
      top: 25px;
      right: 10px;
      padding: 40px;
    }
  }
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`

export const Title = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: ${props => props.theme.navy};
  margin-bottom: 8px;

  @media (max-width: ${LG}) {
    line-height: 36px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`
export const Place = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${props => props.theme.navy};

  &:after {
    content: "";
    display: block;
    width: 64px;
    height: 4px;
    background: ${props => props.theme.orangeGradient};
    margin-top: 20px;
  }

  @media (max-width: ${LG}) {
    margin-bottom: 0;
    &:after {
      margin-top: 12px;
    }
  }
`

export const ModalImage = styled.div`
  box-shadow: 1px 28px 60px rgba(0, 43, 52, 0.08);
  border-radius: 8px;
  max-width: 211px;
  height: 211px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

  img {
    width: 100%;
    max-width: 121px;
  }

  @media (max-width: ${LG}) {
    max-width: 127px;
    height: 127px;
  }
`

export const TextContainer = styled.div`
/*   overflow: scroll;

  @media (max-height: 800px) {
    min-height: 200px;
    max-height: 220px;
  }

  @media (max-width: ${BREAKPOINT_CEL_ONLY}) {
    min-height: auto;
    max-height: none;
  } */

  padding-bottom:50px;
`

export const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 31px;
  color: ${props => props.theme.navy};
  margin-top: 30px;

  @media (max-width: ${BREAKPOINT_CEL_ONLY}) {
    font-size: 16px;
    line-height: 24px;
  }
`
export const ExternalLinksWrap = styled.div`
  display: grid;
  align-items: center;
  background-color: ${props => props.theme.neutralSilver};
  border-radius: 8px;

  position: relative;
  bottom: 20px;
  /*   width: calc(100% - 50px); */
  left: 50%;
  transform: translate(-50%, 0);

  p {
    margin: 0;
    margin-right: 0;
    /*   padding: 30px 0 30px 30px; */
    width: auto;
    overflow: hidden;
    a {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: ${props => props.theme.neutralDark};
      display: flex;
      align-items: center;
      opacity: 0.8;
      padding: 30px 0 30px 30px;

      &:after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        background: url(${ArrowIcon}) no-repeat center center;
        width: 12px;
        height: 7px;
        margin-left: 5px;
        transition: ${props => props.theme.defaultTransition("all", 0.2)};
      }

      &:hover {
        opacity: 1;
        &:after {
          margin-left: 10px;
        }
      }

      @media (max-width: ${BREAKPOINT_CEL_ONLY}) {
        max-width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }
    }
  }

  @media (max-width: ${LG}) {
    position: inherit;
    bottom: unset;
    width: 100%;
    left: unset;
    transform: unset;
    display: block;
    margin-left: -30px;
    margin-top: 30px;
    padding-top: 15px;
    padding-bottom: 20px;

    p {
      padding: 15px 0px 15px 0;
      width: auto;
    }
  }
`

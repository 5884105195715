import styled from "styled-components"
import { LG } from "../../../utils/constants"
import {
  MainH3,
  MainParagraph,
  PrimaryBtn,
} from "../../../utils/GlobalComponents"

export const Wrapper = styled.div`
  background-color: #ffffff;
  display: grid;
  grid-template-columns: 0.95fr 1fr;

  box-shadow: 1px 28px 60px rgba(0, 43, 52, 0.08);
  border-radius: 8px;

  > div {
    &:first-child {
      padding: 40px 50px;
    }
  }

  > img {
    width: 100%;
  }

  ul {
    display: flex;
    align-items: center;

    li {
      display: flex;
      align-items: center;
      margin-right: 20px;

      img {
        width: 22px;
      }

      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: ${props => props.theme.neutralDark};
        padding-left: 10px;
      }
    }
    @media (max-width: ${LG}) {
      display: block;

      li {
        margin-bottom: 20px;
        img {
          width: 28px;
          height: 28px;
        }
        span {
          padding-top: 5px;
        }
      }
    }
  }

  @media (max-width: ${LG}) {
    display: flex;
    flex-direction: column-reverse;

    > img {
      height: 200px;
    }

    > div {
      &:first-child {
        padding: 20px 35px 30px;
      }
    }
  }
`

export const Title = styled(MainH3)`
  color: ${props => props.theme.neutralDark};
  font-weight: 500;
  margin-bottom: 15px;
  margin-top: 10px;
`

export const Text = styled(MainParagraph)`
  color: ${props => props.theme.neutralDark};
  max-width: 399px;
  margin-bottom: 80px;

  @media (max-width: ${LG}) {
    max-width: unset;
    margin-bottom: 30px;
  }
`

export const Btn = styled(PrimaryBtn)``

export const ImageWrapper = styled.div`
  background: url(${props => props.bg}) no-repeat center center / cover;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  align-items: center;
  display: flex;
  justify-content: center;

  @media (max-width: ${LG}) {
    height: 60vw;
    border-radius: 8px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
`
